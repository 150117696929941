"use client"

import type { Nullish } from "~/@types/generics"
import type { SbNavigationTextColor, SbNavigationTheme } from "~/lib/storyblok/types"
import { useNavigationTextColor, useNavigationTheme } from "~/components/abstracts/Navigation/hooks"

type NavigationThemeAndTextColorSetterProps = {
  theme: Nullish<SbNavigationTheme>
  color: Nullish<SbNavigationTextColor>
}

function NavigationThemeSetter({ theme, color }: NavigationThemeAndTextColorSetterProps) {
  const [, setNavigationTheme] = useNavigationTheme()
  const [, setNavigationTextColor] = useNavigationTextColor()

  setNavigationTheme(theme ?? "white")
  setNavigationTextColor(color ?? "ui-900")

  return null
}

export { NavigationThemeSetter, type NavigationThemeAndTextColorSetterProps }
